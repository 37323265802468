var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-sm-3 py-10 prototype-container" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass:
                "pb-md-16 mb-md-10 d-flex align-center justify-center",
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? "mew-subtitle"
                      : "mew-title",
                    "font-weight-regular text-center pb-5 pb-lg-10",
                  ],
                },
                [
                  _vm._v(" Ad opportunities "),
                  _c("br", { staticClass: "d-md-none" }),
                  _vm._v(" with XOW web "),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "d-flex justify-center px-sm-5 pb-12 pb-sm-16 mb-sm-10",
              attrs: { cols: "12", md: "12" },
            },
            [
              _c("img", {
                staticClass: "ad-prototype-img",
                attrs: {
                  src: require("@/assets/images/backgrounds/ad-prototype.png"),
                  width: "100%",
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-10 pb-2 d-flex align-center justify-center",
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? "mew-subtitle"
                      : "mew-title",
                    "font-weight-regular text-center pb-5 pb-md-10",
                  ],
                },
                [
                  _vm._v(" Ad opportunities with "),
                  _c("br", { staticClass: "d-md-none" }),
                  _vm._v(" XOW mobile app "),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                "d-flex justify-center px-16 px-sm-5 pb-12 pb-sm-16 mb-sm-10",
              attrs: { cols: "12", md: "12" },
            },
            [
              _c("img", {
                staticClass: "ad-mew-wallet-img",
                attrs: {
                  src: require("@/assets/images/backgrounds/ad-mew-wallet.png"),
                  width: "100%",
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "pb-3 d-flex align-center justify-center",
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? "mew-subtitle"
                      : "mew-title",
                    "font-weight-regular text-center pb-5 pb-md-10",
                  ],
                },
                [
                  _vm._v(" Ad opportunities with "),
                  _c("br", { staticClass: "d-md-none" }),
                  _vm._v(" XOW's block explorer:"),
                  _c("br", { staticClass: "d-md-none" }),
                  _vm._v(" ethVM "),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center px-sm-5 pb-16 mb-16",
              attrs: { cols: "12", md: "12" },
            },
            [
              _c("img", {
                staticClass: "ad-prototype-img",
                attrs: {
                  src: require("@/assets/images/backgrounds/ad-ethvm.png"),
                  width: "100%",
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }