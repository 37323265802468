var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-overlay", {
    attrs: { "show-overlay": _vm.open },
    scopedSlots: _vm._u([
      {
        key: "mewOverlayBody",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("h2", { staticClass: "text-center mb-10" }, [
                  _vm._v("Buy domain"),
                ]),
                _c("white-sheet", [
                  _c(
                    "div",
                    { staticClass: "pa-8" },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass:
                            "mx-auto mb-10 border-radius--10px informationBlock py-5 px-7",
                          attrs: { color: "transparent", width: "600px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between mb-3",
                            },
                            [
                              _c("div", [_vm._v("Domain name:")]),
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v("mewwallet.crypto"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c("div", [_vm._v("Price:")]),
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v("0.002353234 ETH ($40.00)"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-sheet",
                        {
                          staticClass: "mx-auto",
                          attrs: { color: "transparent", width: "450px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between mb-5",
                            },
                            [
                              _c("div", { staticClass: "mew-heading-1" }, [
                                _vm._v("Pay with Crypto"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-medium greenPrimary--text",
                                },
                                [_vm._v(" Pay with Credit Card ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "pa-4 d-flex align-center justify-space-between bordered-red",
                              staticStyle: {
                                "border-color":
                                  "var(--v-redPrimary-base) !important",
                              },
                              attrs: {
                                outlined: "",
                                color: "informationBlock",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/currencies/icon-eth-blue.svg"),
                                      alt: "Crypto",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium ml-3" },
                                    [
                                      _vm._v(" 0.0341234234 "),
                                      _c(
                                        "span",
                                        { staticClass: "greenPrimary--text" },
                                        [_vm._v("ETH")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-icon",
                                { staticClass: "greenPrimary--text" },
                                [_vm._v("mdi-check-circle")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "redPrimary--text mt-3 mb-7 font-weight-medium",
                            },
                            [
                              _vm._v(" Insufficient balance. "),
                              _vm.network.type.canBuy
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "text-decoration--underline",
                                      on: { click: _vm.openBuySell },
                                    },
                                    [
                                      _vm._v(
                                        " Buy more " +
                                          _vm._s(_vm.network.type.name) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "mt-3 pa-4 d-flex align-center justify-space-between",
                              attrs: { flat: "", color: "informationBlock" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/icons/icon-fiat-white.svg"),
                                      alt: "Fiat",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium ml-3" },
                                    [
                                      _vm._v(" 40.23 "),
                                      _c(
                                        "span",
                                        { staticClass: "greenPrimary--text" },
                                        [_vm._v("USD")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("v-icon", [
                                _vm._v("mdi-check-circle-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mt-5" },
                            [
                              _c("mew-button", {
                                attrs: { title: "Pay", "btn-size": "xlarge" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("h2", { staticClass: "text-center mb-10" }, [
                  _vm._v("Confirmation"),
                ]),
                _c("white-sheet", [_c("transaction-confirmation")], 1),
                _c("white-sheet", [
                  _c(
                    "div",
                    { staticClass: "pa-8" },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass:
                            "mx-auto mb-10 border-radius--10px informationBlock py-5 px-7",
                          attrs: { color: "transparent" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c("div", [_vm._v("Domain name:")]),
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v("mewwallet.crypto"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("v-progress-linear", {
                        staticStyle: {
                          margin: "130px auto 40px auto",
                          "max-width": "200px",
                        },
                        attrs: { indeterminate: "", color: "greenPrimary" },
                      }),
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v("Processing the registration"),
                      ]),
                      _c(
                        "v-sheet",
                        {
                          staticClass: "text-center mx-auto mt-3",
                          attrs: { color: "transparent", "max-width": "300px" },
                        },
                        [
                          _vm._v(
                            " Please do not exit the Dapp or close your browser, it takes a short while for registration… "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "py-10" }),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }