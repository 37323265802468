var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-overlay", {
    attrs: { "show-overlay": _vm.open },
    scopedSlots: _vm._u([
      {
        key: "mewOverlayBody",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "pa-8" },
              [
                _c("h2", { staticClass: "text-center mb-10" }, [
                  _vm._v("Add an owned domain"),
                ]),
                _c(
                  "v-sheet",
                  { attrs: { color: "transparent", width: "700px" } },
                  [
                    _c("div", { staticClass: "mb-3" }),
                    _c(
                      "div",
                      { staticClass: "d-flex align-start" },
                      [
                        _c("mew-input", {
                          staticClass: "mr-3 flex-grow-1",
                          attrs: {
                            "has-clear-btn": true,
                            "right-label": ".ctypto",
                            label: "Domain name",
                            placeholder: " ",
                          },
                        }),
                        _c("mew-button", {
                          attrs: {
                            "has-full-width": false,
                            "btn-size": "xlarge",
                            title: "Search",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("white-sheet", { staticClass: "pa-3" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between informationBlock pa-6 border-radius--10px",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "check-icon greenPrimary--text mr-3",
                            },
                            [_vm._v(" mdi-check-circle-outline ")]
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mew-heading-2 greenPrimary--text mb-2",
                              },
                              [_vm._v(" Owned domain ")]
                            ),
                            _c("div", { staticClass: "mew-heading-2" }, [
                              _vm._v("mewdev009.eth"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("mew-button", {
                        attrs: {
                          "has-full-width": false,
                          "btn-size": "large",
                          title: "Add to list",
                          "btn-style": "outline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "pa-5" }, [
                    _c("table", [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("Parent")]),
                          _c("td", [_vm._v("ETH")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Registrant")]),
                          _c(
                            "td",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("mew-blockie", {
                                staticClass: "mr-2",
                                attrs: { height: "30px", width: "30px" },
                              }),
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v(
                                  " 0xf55914186a692a3335fF44ae107FA724b1074dDC "
                                ),
                              ]),
                              _c("mew-copy", {
                                attrs: {
                                  tooltip: _vm.$t("common.copy"),
                                  "copy-value":
                                    "0xf55914186a692a3335fF44ae107FA724b1074dDC",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Controller")]),
                          _c(
                            "td",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("mew-blockie", {
                                staticClass: "mr-2",
                                attrs: { height: "30px", width: "30px" },
                              }),
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v(
                                  " 0xf55914186a692a3335fF44ae107FA724b1074dDC "
                                ),
                              ]),
                              _c("mew-copy", {
                                attrs: {
                                  tooltip: _vm.$t("common.copy"),
                                  "copy-value":
                                    "0xf55914186a692a3335fF44ae107FA724b1074dDC",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("Expiration date")]),
                          _c(
                            "td",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(" 03/12/2020 @ 20:30:12 "),
                              _c("badge", {
                                staticClass: "ml-2",
                                attrs: {
                                  "badge-title": "Expired",
                                  "badge-type": "error",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }