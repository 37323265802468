var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-container position--relative" }, [
    _c(
      "div",
      { staticClass: "pb-12 pb-lg-0", attrs: { id: "swap" } },
      [
        _c("img", {
          staticClass: "d-none d-lg-block block-background-pattern",
          attrs: { src: require("@/assets/images/backgrounds/bg-bubbles.png") },
        }),
        _c("div", { staticClass: "py-6 py-md-12" }),
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", lg: "6" } },
                  [
                    _c("v-img", {
                      staticClass: "mb-8 block-image",
                      attrs: {
                        src: require("@/assets/images/snippets/swap-page.png"),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "text-content pl-lg-12",
                    attrs: { cols: "12", lg: "6" },
                  },
                  [
                    _c("app-block-title", {
                      staticClass: "mb-12 pl-12",
                      attrs: { "no-page-title": "", data: _vm.titleData },
                    }),
                    _c("div", { staticClass: "mb-4 d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/images/currencies/icon-btc-white.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-1 font-weight-bold subtitle-1 white--text",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("howItWorks.swap.info-one.title"),
                                    "howItWorks.swap.info-one.title"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "white--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t("howItWorks.swap.info-one.text"),
                                  "howItWorks.swap.info-one.text"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "mb-4 d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/images/currencies//icon-eth-white.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-1 font-weight-bold subtitle-1 white--text",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("howItWorks.swap.info-two.title"),
                                    "howItWorks.swap.info-two.title"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "white--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("$t")(
                                  _vm.$t("howItWorks.swap.info-two.text"),
                                  "howItWorks.swap.info-two.text"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/images/icons/icon-fiat-white.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-1 font-weight-bold subtitle-1 white--text",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("lokalise")(
                                    _vm.$t("howItWorks.swap.info-three.title"),
                                    "howItWorks.swap.info-three.title"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "white--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("lokalise")(
                                  _vm.$t("howItWorks.swap.info-three.text"),
                                  "howItWorks.swap.info-three.text"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }