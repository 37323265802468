var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--paper-wallet-content" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center mr-auto",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("img", {
                    attrs: {
                      height: "35",
                      src: require("@/assets/images/icons/logo-mew-dark.png"),
                    },
                  }),
                  _c("div", { staticClass: "greenPrimary--text" }, [
                    _c("span", { staticClass: "mx-3" }, [_vm._v("|")]),
                    _vm._v("Paper Wallet "),
                  ]),
                ]
              ),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center mr-3 mb-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "textDark", size: "20px" },
                      },
                      [_vm._v("mdi-face-agent")]
                    ),
                    _c("div", [_vm._v("support@xinfin.org")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center mr-3" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "textDark", size: "20px" },
                      },
                      [_vm._v("mdi-web")]
                    ),
                    _c("div", [_vm._v("https://wallet.xinfin.network")]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3 mt-md-12 d-block d-md-flex align-center" },
        [
          _c("mew-blockie", {
            staticClass: "mr-6",
            attrs: { address: _vm.address, width: "110px", height: "110px" },
          }),
          _vm._m(0),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-6" }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "align-center" },
            [
              _c(
                "v-col",
                { staticClass: "mr-auto", attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mew-heading-1 word-break--break-all font-weight-black text-uppercase",
                    },
                    [_vm._v(" My wallet address ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mew-heading-3 mew-address word-break--break-all font-weight-bold mt-4",
                    },
                    [_vm._v(" " + _vm._s(_vm.getChecksumAddressString) + " ")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("app-qr-code", {
                    attrs: {
                      data: _vm.getXDCAddress(_vm.address),
                      height: 140,
                      width: 140,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showPrivateKey
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-auto", attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-1 font-weight-black text-uppercase redPrimary--text",
                        },
                        [
                          _c("v-icon", { attrs: { color: "redPrimary" } }, [
                            _vm._v("mdi-alert"),
                          ]),
                          _vm._v(" My Private Key "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-black redPrimary--text mt-2",
                        },
                        [
                          _vm._v(
                            " You might LOSE your MONEY if you share this Private Key with anyone! KEEP YOUR PRIVATE KEY IN SAFE PLACE! "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-3 mew-address font-weight-bold word-break--break-all mt-4",
                        },
                        [_vm._v(" " + _vm._s(_vm.key) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("app-qr-code", {
                        attrs: {
                          data: _vm.getXDCAddress(_vm.key),
                          height: 140,
                          width: 140,
                          "type-number": 10,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "my-3", staticStyle: { "max-width": "400px" } },
      [
        _c(
          "div",
          {
            staticClass: "mew-heading-1 font-weight-black text-uppercase mb-2",
          },
          [_vm._v(" My address icon ")]
        ),
        _c("div", [
          _vm._v(
            " Always look for the icon when sending to this wallet. And please keep your paper wallet at a "
          ),
          _c(
            "span",
            {
              staticClass: "text-uppercase redPrimary--text font-weight-medium",
            },
            [_vm._v(" Safe Place! ")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }