var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-overlay", {
    attrs: { "show-overlay": _vm.open },
    scopedSlots: _vm._u([
      {
        key: "mewOverlayBody",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("h2", { staticClass: "text-center mb-10" }, [
                  _vm._v("Transfer domain"),
                ]),
                _c(
                  "white-sheet",
                  [
                    _c(
                      "v-sheet",
                      {
                        staticClass: "pa-8",
                        attrs: { color: "transparent", width: "600px" },
                      },
                      [
                        _c("mew-address-select"),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center mt-2" },
                          [
                            _c("mew-button", {
                              attrs: { title: "Pay", "btn-size": "xlarge" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("h2", { staticClass: "text-center mb-10" }, [
                  _vm._v("Confirmation"),
                ]),
                _c("white-sheet"),
                _c("white-sheet", [
                  _c(
                    "div",
                    { staticClass: "pa-8" },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass:
                            "mx-auto mb-10 border-radius--10px informationBlock py-5 px-7",
                          attrs: { color: "transparent" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between",
                            },
                            [
                              _c("div", [_vm._v("Domain name:")]),
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v("mewwallet.crypto"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("v-progress-linear", {
                        staticStyle: {
                          margin: "130px auto 40px auto",
                          "max-width": "200px",
                        },
                        attrs: { indeterminate: "", color: "greenPrimary" },
                      }),
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v("Processing the registration"),
                      ]),
                      _c(
                        "v-sheet",
                        {
                          staticClass: "text-center mx-auto mt-3",
                          attrs: { color: "transparent", "max-width": "300px" },
                        },
                        [
                          _vm._v(
                            " Please do not exit the Dapp or close your browser, it takes a short while for registration… "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "py-10" }),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }