var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component-fix--unstoppable-domain" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("buy-overlay", {
            attrs: { open: _vm.buyOverlay, close: _vm.closeBuyOverlay },
          }),
          _c(
            "div",
            {
              staticClass: "cursor--pointer font-weight-bold mr-4",
              on: {
                click: function ($event) {
                  _vm.buyOverlay = true
                },
              },
            },
            [_vm._v(" Buy Domain Overlay ")]
          ),
          _c("add-owned-domain-overlay", {
            attrs: { open: _vm.addOverlay, close: _vm.closeAddOverlay },
          }),
          _c(
            "div",
            {
              staticClass: "cursor--pointer font-weight-bold mr-4",
              on: {
                click: function ($event) {
                  _vm.addOverlay = true
                },
              },
            },
            [_vm._v(" Add Owned Domain ")]
          ),
          _c("transfer-domain-overlay", {
            attrs: {
              open: _vm.transferOverlay,
              close: _vm.closeTransferOverlay,
            },
          }),
          _c(
            "div",
            {
              staticClass: "cursor--pointer font-weight-bold mr-4",
              on: {
                click: function ($event) {
                  _vm.transferOverlay = true
                },
              },
            },
            [_vm._v(" Transfer Domain ")]
          ),
        ],
        1
      ),
      _c(
        "white-sheet",
        [
          _c("mew-banner", {
            attrs: { "text-obj": _vm.topBanner, "banner-img": _vm.BG },
          }),
          _c("mew-tabs", {
            attrs: { items: _vm.tabs, "has-underline": "" },
            scopedSlots: _vm._u([
              {
                key: "tabContent1",
                fn: function () {
                  return [
                    _c(
                      "v-sheet",
                      {
                        staticClass: "mx-auto py-12",
                        attrs: { color: "transparent", "max-width": "700px" },
                      },
                      [
                        _c("div", { staticClass: "mb-5" }, [
                          _c("div", { staticClass: "mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "mew-heading-3 font-weight-bold" },
                              [_vm._v(" Find your blockchain domain ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-start" },
                            [
                              _c("mew-input", {
                                staticClass: "mr-3 flex-grow-1",
                                attrs: {
                                  "has-clear-btn": true,
                                  "right-label": ".ctypto",
                                  label: "Domain name",
                                  placeholder: " ",
                                },
                              }),
                              _c("mew-button", {
                                attrs: {
                                  "has-full-width": false,
                                  "btn-size": "xlarge",
                                  title: "Check availability",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "mew-heading-3 font-weight-bold",
                                },
                                [_vm._v("Results")]
                              ),
                            ]),
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-10",
                                attrs: { flat: "", color: "greyLight" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center",
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "mew-heading-1 mb-2" },
                                        [_vm._v("mewwallet.crypto")]
                                      ),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-medium" },
                                          [_vm._v("0.0823234234")]
                                        ),
                                        _vm._v(" ETH ($40.00) "),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mew-heading-3 greenPrimary--text mr-6",
                                          },
                                          [_vm._v(" Available ")]
                                        ),
                                        _c("mew-button", {
                                          attrs: { title: "Buy" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "py-2" }),
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-10",
                                attrs: { flat: "", color: "greyLight" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between mb-8",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mew-heading-1" },
                                      [_vm._v("myetherwallet.crypto")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mew-heading-3 orange--text",
                                      },
                                      [_vm._v("Unavailable")]
                                    ),
                                  ]
                                ),
                                _c("v-divider", { staticClass: "mb-5" }),
                                _vm._l(_vm.results, function (r, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: key,
                                      staticClass: "px-0",
                                      attrs: { "two-line": "" },
                                    },
                                    [
                                      _c("v-list-item-content", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mew-body font-weight-medium textDark--text mb-2",
                                          },
                                          [_vm._v(" " + _vm._s(r.label) + ": ")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mew-body textDark--text",
                                          },
                                          [_vm._v(" " + _vm._s(r.value) + " ")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "py-10" }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between pa-10 mb-10",
                                attrs: { flat: "", color: "greyLight" },
                              },
                              [
                                _c("div", { staticClass: "mew-heading-1" }, [
                                  _vm._v("mewwallet.crypto"),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v("0.0341234234 ETH")]
                                  ),
                                  _vm._v(" ($40.00) "),
                                ]),
                              ]
                            ),
                            _c(
                              "v-sheet",
                              {
                                staticClass: "mx-auto",
                                attrs: {
                                  color: "transparent",
                                  "max-width": "400px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between mb-5",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mew-heading-1" },
                                      [_vm._v("Pay with Crypto")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-medium greenPrimary--text",
                                      },
                                      [_vm._v(" Pay with Credit Card ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "pa-4 d-flex align-center justify-space-between bordered-red",
                                    staticStyle: {
                                      "border-color":
                                        "var(--v-redPrimary-base) !important",
                                    },
                                    attrs: { outlined: "", color: "greyLight" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/currencies/icon-eth-blue.svg"),
                                            alt: "Crypto",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-medium ml-3",
                                          },
                                          [
                                            _vm._v(" 0.0341234234 "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "greenPrimary--text",
                                              },
                                              [_vm._v("ETH")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-icon",
                                      { staticClass: "greenPrimary--text" },
                                      [_vm._v("mdi-check-circle")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "redPrimary--text mt-3 mb-7 font-weight-medium",
                                  },
                                  [
                                    _vm._v(" Insufficient balance. "),
                                    _vm.network.type.canBuy
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-decoration--underline",
                                            on: { click: _vm.openBuySell },
                                          },
                                          [
                                            _vm._v(
                                              " Buy more " +
                                                _vm._s(_vm.network.type.name) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "mt-3 pa-4 d-flex align-center justify-space-between",
                                    attrs: { flat: "", color: "greyLight" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/icons/icon-fiat-white.svg"),
                                            alt: "Fiat",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-medium ml-3",
                                          },
                                          [
                                            _vm._v(" 40.23 "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "greenPrimary--text",
                                              },
                                              [_vm._v("USD")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("v-icon", [
                                      _vm._v("mdi-check-circle-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center mt-5" },
                                  [
                                    _c("mew-button", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        title: "Back",
                                        "btn-style": "outline",
                                        "btn-size": "xlarge",
                                      },
                                    }),
                                    _c("mew-button", {
                                      attrs: {
                                        title: "Pay",
                                        "btn-size": "xlarge",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "tabContent2",
                fn: function () {
                  return [
                    _c("div", { staticClass: "pa-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between mb-7",
                        },
                        [
                          _c("h3", [
                            _vm._v("My domains "),
                            _c("span", { staticClass: "font-weight-regular" }, [
                              _vm._v("(1)"),
                            ]),
                          ]),
                          _c("mew-button", {
                            attrs: {
                              "btn-style": "outline",
                              title: "+ Add domain you own",
                              "btn-size": "large",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("mew-expand-panel", {
                            attrs: { "panel-items": _vm.myDomains },
                            scopedSlots: _vm._u([
                              {
                                key: "panelBody1",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "px-5" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "header-block bg_datablock",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Registrant"),
                                                      ]),
                                                      _c("mew-blockie", {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          width: "25px",
                                                          height: "25px",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "monospace truncate",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Controller"),
                                                      ]),
                                                      _c("mew-blockie", {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          width: "25px",
                                                          height: "25px",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "monospace truncate",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-space-between border-bottom py-5 px-0",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mew-heading-3",
                                                },
                                                [
                                                  _vm._v(
                                                    " What do you want to do with the domain? "
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v("Parent - ETH"),
                                              ]),
                                            ]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "div",
                                            { staticClass: "pa-5" },
                                            [
                                              _c(
                                                "v-row",
                                                _vm._l(
                                                  _vm.domainFunctions,
                                                  function (f, key) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: key,
                                                        staticClass:
                                                          "text-center",
                                                        attrs: { cols: "2" },
                                                      },
                                                      [
                                                        _c("mew-icon", {
                                                          attrs: {
                                                            "icon-name":
                                                              "ensManager",
                                                            "img-height": 75,
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(f.label)
                                                          ),
                                                        ]),
                                                        f.expire
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "orange--text",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Expire at"
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      f.expire
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "panelBody2",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "px-5" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "header-block bg_datablock",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Registrant"),
                                                      ]),
                                                      _c("mew-blockie", {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          width: "25px",
                                                          height: "25px",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "monospace truncate",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v("Controller"),
                                                      ]),
                                                      _c("mew-blockie", {
                                                        staticClass: "mx-3",
                                                        attrs: {
                                                          width: "25px",
                                                          height: "25px",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "monospace truncate",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 0xD4289C524f3A75b783497EB5a459a54F6F4df8D1aaaaaaaaaaaaaa "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-space-between border-bottom py-5 px-0",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mew-heading-3",
                                                },
                                                [
                                                  _vm._v(
                                                    " What do you want to do with the domain? "
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v("Parent - ETH"),
                                              ]),
                                            ]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "div",
                                            { staticClass: "pa-5" },
                                            [
                                              _c(
                                                "v-row",
                                                _vm._l(
                                                  _vm.domainFunctions,
                                                  function (f, key) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: key,
                                                        staticClass:
                                                          "text-center",
                                                        attrs: { cols: "2" },
                                                      },
                                                      [
                                                        _c("mew-icon", {
                                                          attrs: {
                                                            "icon-name":
                                                              "ensManager",
                                                            "img-height": 75,
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(f.label)
                                                          ),
                                                        ]),
                                                        f.expire
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "orange--text",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Expire at"
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      f.expire
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "tabContent3",
                fn: function () {
                  return [
                    _c(
                      "v-sheet",
                      {
                        staticClass: "mx-auto py-12",
                        attrs: { color: "transparent", "max-width": "700px" },
                      },
                      [
                        _c("div", { staticClass: "mb-5" }, [
                          _c("div", { staticClass: "mb-3" }, [
                            _c("div", {
                              staticClass:
                                "addressBlock d-flex align-center mb-7",
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column justify-space-between",
                            },
                            [
                              _c("div", { staticClass: "mew-heading-3 mb-2" }, [
                                _vm._v(" Reverse Resolution for Address "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("mew-input", {
                                    staticClass: "mr-3 mt-10 flex-grow-1",
                                    attrs: {
                                      value: _vm.address,
                                      "show-blockie": true,
                                      "hide-clear-btn": true,
                                      "is-read-only": true,
                                      loading: true,
                                      "error-messages": _vm.inputErrorMessage,
                                      label: "Wallet Address",
                                    },
                                    on: { input: _vm.setAddress },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-space-between",
                                    },
                                    [
                                      _c("mew-button", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          "has-full-width": false,
                                          "btn-size": "xlarge",
                                          title: "Reverse TokenId",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.reverseTokenId()
                                          },
                                        },
                                      }),
                                      _c("mew-button", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          "has-full-width": false,
                                          "btn-size": "xlarge",
                                          title: "Reverse Url",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.reverseUrl()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.hasTokenIdResults
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-column mt-2" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-heading-3 font-weight-heavy",
                                    },
                                    [
                                      _vm._v(
                                        "Reverse Resolve TokenId by Address Result: "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-heavy mt-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.reverseTokenIdResults) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.hasUrlResults
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-column mt-2" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-heading-3 font-weight-heavy",
                                    },
                                    [
                                      _vm._v(
                                        "Reverse Resolve Url by Address Result: "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-heavy mt-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.reverseUrlResults) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }