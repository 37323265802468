var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-sheet",
        {
          staticClass:
            "addressBlock d-flex justify-space-between align-center mb-7",
        },
        [
          _c("mew-blockie", { attrs: { address: _vm.address } }),
          _c("span", { staticClass: "font-weight-heavy pr-15" }, [
            _vm._v(_vm._s(_vm.address)),
          ]),
        ],
        1
      ),
      !_vm.hasDomains
        ? _c(
            "div",
            [
              _c(
                "mew-alert",
                {
                  staticClass:
                    "font-weight-light d-flex justify-space-between align-center mb-7 mt-7",
                  attrs: { "hide-close-icon": "" },
                },
                [
                  _vm._v(
                    " No RNS names have their Rootstock Address records set to this address. "
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex flex-column justify-space-between" },
            [
              _c("div", { staticClass: "mew-heading-2 mb-2" }, [
                _vm._v("Your Domain:"),
              ]),
              _c(
                "a",
                {
                  staticClass: "d-flex justify-space-between",
                  attrs: {
                    target: "_blank",
                    href:
                      "https://manager.rns.rifos.org/resolve?name=" +
                      _vm.reverseRecordName,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.reverseRecordName) + " ")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }