var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mew-component--tools",
      class: _vm.$vuetify.breakpoint.smAndDown ? "mobile" : "desktop",
    },
    [
      _c("the-layout-header", {
        staticClass: "pt-16",
        attrs: { title: "Tools" },
      }),
      _c(
        "v-container",
        { staticClass: "px-3 my-12" },
        [
          _c("mew-tabs", {
            attrs: {
              "is-vertical": _vm.$vuetify.breakpoint.smAndDown ? false : true,
              compact: _vm.$vuetify.breakpoint.smAndDown,
              items: _vm.items,
              "active-tab": _vm.activeTab,
              "show-arrows": "",
            },
            on: { onTab: _vm.tabChanged },
            scopedSlots: _vm._u([
              {
                key: "tabItemContent1",
                fn: function () {
                  return [
                    _c("module-message-verify", { ref: "verifyMessageModule" }),
                  ]
                },
                proxy: true,
              },
              {
                key: "tabItemContent2",
                fn: function () {
                  return [_c("module-tools-convert")]
                },
                proxy: true,
              },
              {
                key: "tabItemContent3",
                fn: function () {
                  return [
                    _c("module-tools-offline-helper", {
                      attrs: { "is-home-page": true },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }