var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component--wallet-card theBalanceCard" },
    [
      _c("div", { staticClass: "mew-card drop-shadow" }, [
        _c("img", {
          attrs: {
            src: "https://mewcard.mewapi.io/?address=" + _vm.address,
            alt: "MEW Card",
          },
          on: {
            load: function ($event) {
              return _vm.animateMewCard()
            },
          },
        }),
      ]),
      _c("div", { staticClass: "info-container pl-8 pr-5 py-4 text-shadow" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-space-between align-start" },
          [
            _c(
              "div",
              { staticClass: "balanceMenu" },
              [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  staticClass:
                                    "d-flex align-center cursor--pointer personal-account-container",
                                },
                                on
                              ),
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-container--text font-weight-bold white--text",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "white--text ml-2",
                                    attrs: { small: "", dense: "" },
                                  },
                                  [_vm._v(" mdi-menu-down ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass: "bgWalletBlock",
                        attrs: { width: "232px" },
                      },
                      [
                        !_vm.isOfflineApp
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: _vm.refresh },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: "textDark" },
                                  },
                                  [_vm._v("mdi-refresh")]
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(" Refresh Balance"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            staticClass: "cursor-pointer openThePaperWallet",
                            on: { click: _vm.openPaperWallet },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-3",
                                attrs: { color: "textDark" },
                              },
                              [_vm._v("mdi-printer")]
                            ),
                            _c("v-list-item-title", [
                              _vm._v("View paper wallet"),
                            ]),
                          ],
                          1
                        ),
                        _vm.isHardware && _vm.canDisplayAddress
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: _vm.viewAddressOnDevice },
                              },
                              [
                                _c("mew-icon", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    "icon-name": _vm.iconIdentifier,
                                    "img-height": 24,
                                  },
                                }),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "View address on " + _vm._s(_vm.walletName)
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-divider", { staticClass: "mx-5 my-4" }),
                        _vm.canSwitch
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: _vm.openChangeAddress },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { color: "textDark" },
                                  },
                                  [_vm._v("mdi-account-box-multiple")]
                                ),
                                _c("v-list-item-title", [
                                  _vm._v("Switch Account"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            staticClass: "cursor-pointer",
                            on: { click: _vm.openLogout },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-3",
                                attrs: { color: "textDark" },
                              },
                              [_vm._v("mdi-logout")]
                            ),
                            _c("v-list-item-title", [_vm._v("Logout")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "mew-tooltip",
                  {
                    attrs: {
                      "hide-icon": "",
                      text: _vm.prefix + _vm.getChecksumAddressString.slice(3),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activatorSlot",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "justify-start d-flex align-center info-container--addr monospace",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getXDCAddress(_vm.addrFirstSix)
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-icon",
                                  { staticClass: "info-container--addr pt-1" },
                                  [_vm._v("mdi-dots-horizontal")]
                                ),
                                _vm._v(" " + _vm._s(_vm.addrlastFour) + " "),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("span", { staticClass: "textDark--text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.prefix + _vm.getChecksumAddressString.slice(3)
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", [
              _c(
                "a",
                { attrs: { href: _vm.blockExplorer, target: "_blank" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "cursor--pointer",
                      attrs: { small: "", color: "white" },
                    },
                    [_vm._v("mdi-open-in-new")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        !_vm.isOfflineApp
          ? _c(
              "div",
              {
                class: [
                  { "ml-n5": !_vm.isTestNetwork },
                  "mew-subtitle text-shadow white--text mt-5 mb-4",
                ],
              },
              [
                _vm.loadingWalletInfo
                  ? _c("v-skeleton-loader", {
                      staticClass: "theme-dark-heading",
                      attrs: { type: "heading" },
                    })
                  : _c(
                      "div",
                      { staticClass: "mew-subtitle text-shadow white--text" },
                      [
                        _vm._v(" " + _vm._s(_vm.totalWalletBalance) + " "),
                        _vm.isTestNetwork
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "padding-left": "2px",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.network.type.currencyName))]
                            )
                          : _vm._e(),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "d-flex justify-space-between align-center",
            style: _vm.isOfflineApp ? "margin-top:74px" : "",
          },
          [
            !_vm.isOfflineApp
              ? _c(
                  "div",
                  { staticClass: "justify-start" },
                  [
                    _vm.loadingWalletInfo
                      ? _c("v-skeleton-loader", {
                          attrs: { type: "text", width: "100" },
                        })
                      : !_vm.isTestNetwork
                      ? _c(
                          "div",
                          { staticClass: "info-container--text-chain-balance" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.walletChainBalance) +
                                " " +
                                _vm._s(_vm.network.type.currencyName) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.loadingWalletInfo
                      ? _c("v-skeleton-loader", {
                          attrs: { type: "text", width: "100" },
                        })
                      : _vm.nonChainTokensCount > 0
                      ? _c("div", { staticClass: "info-container--text" }, [
                          _vm._v(
                            " and " +
                              _vm._s(_vm.nonChainTokensCount) +
                              " Tokens "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "info-container--action-btn mr-2 px-0 BalanceCardQR",
                    attrs: { fab: "", depressed: "" },
                    on: { click: _vm.open },
                  },
                  [
                    _c("img", {
                      staticClass: "info-container--icon",
                      attrs: {
                        height: "18px",
                        src: require("@/assets/images/icons/icon-qr-code.svg"),
                        alt: "qr-code",
                      },
                    }),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "info-container--action-btn px-0",
                    attrs: { depressed: "", fab: "" },
                    on: { click: _vm.copyAddress },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "info-container--icon",
                        attrs: { small: "", color: "white" },
                      },
                      [_vm._v(" mdi-content-copy ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("app-modal", {
        attrs: {
          show: _vm.openQR,
          close: _vm.closeQR,
          "has-buttons": false,
          width: "408px",
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [_c("app-addr-qr")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showHardware
        ? _c("module-access-wallet-hardware", {
            attrs: {
              open: _vm.showChangeAddress,
              close: _vm.closeChangeAddress,
              "switch-address": _vm.instancePath,
            },
          })
        : _c("module-access-wallet-software", {
            attrs: {
              open: _vm.showChangeAddress,
              close: _vm.closeChangeAddress,
              "switch-address": _vm.instancePath,
              "wallet-type": _vm.identifier,
            },
          }),
      _c("mew-popup", {
        attrs: {
          "max-width": "400px",
          "hide-close-btn": "",
          show: _vm.showLogout,
          title: _vm.$t("interface.menu.logout"),
          "left-btn": {
            text: "Cancel",
            method: _vm.closeLogout,
            color: "basic",
          },
          "right-btn": {
            text: "Log out",
            color: "error",
            method: _vm.onLogout,
            enabled: true,
          },
        },
      }),
      _c(
        "mew-popup",
        {
          attrs: {
            "max-width": "515px",
            show: _vm.showVerify,
            title: _vm.verifyAddressTitle,
            "has-buttons": false,
            "has-body-content": true,
            "left-btn": {
              text: "Cancel",
              method: _vm.closeVerify,
              color: "basic",
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "text-center" }, [
              _vm._v(" " + _vm._s(_vm.verifyAddressBody) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "mt-3 verify-popup-border px-12 py-5 text-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold greenPrimary--text mew-body",
                  },
                  [_vm._v(" ACCOUNT ADDRESS ")]
                ),
                _c("div", { staticClass: "pt-3 greenPrimary--text mew-body" }, [
                  _vm._v(" " + _vm._s(_vm.getChecksumAddressString) + " "),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }